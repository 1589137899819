<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <h6 class="section-label mt-2 mb-1 px-2">
              Requirement Filters
            </h6>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="label in requirementFilter"
                :key="label.title + $route.path"
                :to="label.route"
                :active="isDynamicRouteActive(label.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${label.color}`"
                />
                <span>{{ label.title }}</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BListGroup, BListGroupItem } from 'bootstrap-vue'
import { isDynamicRouteActive, useRouter } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  setup() {
    const perfectScrollbarSettings = { maxScrollbarLength: 60 }

    const { route } = useRouter()
    const params = {
      modelId: sessionStorage.getItem('kompozition-workspace'),
      specId: route.value.params.specId,
      snapId: route.value.params.snapId,
    }
    const requirementFilter = [
      { title: 'All', color: 'info', route: { name: 'specification_snapshot_compare', params: { ...params } } },
      { title: 'New', color: 'success', route: { name: 'specification_snapshot_compare_filter', params: { filter: 'new', ...params } } },
      { title: 'Changed', color: 'primary', route: { name: 'specification_snapshot_compare_filter', params: { filter: 'changed', ...params } } },
      { title: 'Deleted', color: 'danger', route: { name: 'specification_snapshot_compare_filter', params: { filter: 'deleted', ...params } } },
      { title: 'Unchanged', color: 'dark', route: { name: 'specification_snapshot_compare_filter', params: { filter: 'unchanged', ...params } } },
    ]

    const resolveFilterBadgeColor = filter => {
      if (filter === 'Draft') return 'light-warning'
      if (filter === 'Spam') return 'light-danger'
      return 'light-primary'
    }

    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
      resolveFilterBadgeColor,
      requirementFilter,
    }
  },
}
</script>
