import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    all: [],
    new: [],
    changed: [],
    deleted: [],
    unchanged: [],
  },
  getters: {},
  mutations: {
    SET_NEW: (state, added) => {
      state.new = added.map(x => ({ ...x, compare_type: 'new' }))
    },
    SET_CHANGED: (state, changed) => {
      state.changed = changed.map(x => ({
        ...x,
        compare_type: 'changed',
        id: x.inCommon.id,
        display_id: x.inCommon.display_id,
      }))
    },
    SET_DELETED: (state, deleted) => {
      state.deleted = deleted.map(x => ({ ...x, compare_type: 'deleted' }))
    },
    SET_UNCHANGED: (state, unchanged) => {
      state.unchanged = unchanged.map(x => ({ ...x, compare_type: 'unchanged' }))
    },
    SET_ALL: state => { state.all = [...state.new, ...state.changed, ...state.deleted, ...state.unchanged] },
  },
  actions: {
    fetchSnapshot({ commit }, payload) {
      return axiosIns
        .get(`/api/v2/specifications/${payload.specId}/snapshot/${payload.snapId}/compare`)
        .then(({ data }) => {
          commit('SET_NEW', data.new)
          commit('SET_CHANGED', data.changed)
          commit('SET_DELETED', data.deleted)
          commit('SET_UNCHANGED', data.unchanged)
          commit('SET_ALL')
        })
        .catch(e => console.error(e))
    },
    filterSnapshotData({ state }, filterParam) {
      if (filterParam === 'new') { return state.new }
      if (filterParam === 'unchanged') { return state.unchanged }
      if (filterParam === 'changed') { return state.changed }
      if (filterParam === 'deleted') { return state.deleted }
      return state.all
    },
  },
}
