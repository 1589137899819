export default function useBaselineView() {
  const resolveCompareTypeColor = label => {
    if (label === 'new') return 'success'
    if (label === 'changed') return 'warning'
    if (label === 'deleted') return 'danger'
    if (label === 'unchanged') return 'dark'
    return 'secondary'
  }

  const resolveCompareTypeBackground = label => {
    if (label === 'new') return 'rgba(40, 199, 111, 0.1)'
    if (label === 'changed') return 'rgba(255, 193, 7, 0.1)'
    if (label === 'deleted') return 'rgba(234, 84, 85, 0.1)'
    return 'false'
  }

  const resolveDiffViewPropertyColour = label => {
    if (label === 'added') return 'success'
    if (label === 'changed') return 'warning'
    if (label === 'removed') return 'danger'
    if (label === 'inCommon') return 'dark'
    return 'secondary'
  }

  const avatarInitial = text => {
    if (text) {
      return text.charAt(0).toUpperCase()
    }
    return 'N/A'
  }

  return {
    resolveCompareTypeColor,
    resolveCompareTypeBackground,
    resolveDiffViewPropertyColour,
    avatarInitial,
  }
}
